
export const BlackFridayOptionsArrOld = [
	{
		mainText: "50%",
		secondaryText: "on-three-month",
		validUntil: "2024-11-16",
		forms: {
			de: "c7d1399a-6711-450e-9a9c-a00adb9eacbb",
			fr: "52f2f2ba-888d-4544-a35e-795287edf764"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-two-month",
		validUntil: "2024-11-26",
		forms: {
			de: "eebd8adc-b527-4dd0-b4a6-2cdae1f7ca7a",
			fr: "17c053ec-e9e6-4967-b5aa-58c0767245d2"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-one-month",
		validUntil: "2024-11-30",
		forms: {
			de: "614a271c-c998-4e07-b6af-0f1f70f5ed97",
			fr: "02eefaeb-b227-41b3-b975-be5fb73f3b3b"
		}
	}
]

export const BlackFridayOptionsArrNew = [
	{
		mainText: "50%",
		secondaryText: "on-three-month",
		validUntil: "2024-11-30",
		forms: {
			de: "c7d1399a-6711-450e-9a9c-a00adb9eacbb",
			fr: "52f2f2ba-888d-4544-a35e-795287edf764"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-two-month",
		validUntil: "2024-11-31",
		forms: {
			de: "eebd8adc-b527-4dd0-b4a6-2cdae1f7ca7a",
			fr: "17c053ec-e9e6-4967-b5aa-58c0767245d2"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-one-month",
		validUntil: "2024-12-02",
		forms: {
			de: "614a271c-c998-4e07-b6af-0f1f70f5ed97",
			fr: "02eefaeb-b227-41b3-b975-be5fb73f3b3b"
		}
	}
]

export const ChristmasWheelOptionsArr = [
	{
		mainText: "50%",
		secondaryText: "on-one-month",
		validUntil: "2025-01-01",
		forms: {
			de: "8d50e761-d7af-423b-93e0-a41eab17f054",
			fr: "9a00a1f2-bc74-460b-b799-6afd8d6e134b"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-two-month",
		validUntil: "2024-12-25",
		forms: {
			de: "6d8623f7-c8cb-47dd-b918-62bfb3508275",
			fr: "73242d11-a611-456c-8e06-5be6c4ae1f2e"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-three-month",
		validUntil: "2024-12-17",
		forms: {
			de: "8ced7c50-f225-4833-bd46-cd52969f4e5e",
			fr: "3003f6c5-6f7e-4b13-b1d5-344b45c14db8"
		}
	},
	{
		mainText: "no-win-title",
		secondaryText: "",
		validUntil: "",
		forms: {
			de: "",
			fr: ""
		}
	}
]

export const NewYearWheelOptionsArr = [
	{
		mainText: "50%",
		secondaryText: "on-two-month",
		validUntil: "2025-02-01",
		forms: {
			de: "45999ce3-4e1e-4554-8854-3f1d71742293",
			fr: "2682dbf4-df12-4062-9ec4-99d7b6f1c053"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-one-month",
		validUntil: "2025-02-01",
		forms: {
			de: "e3ef740d-8b09-4733-a260-49ff37461b40",
			fr: "7b5979e7-32de-41dc-b7f1-ebff08e9053c"
		}
	},
	{
		mainText: "no-win-title",
		secondaryText: "",
		validUntil: "",
		forms: {
			de: "",
			fr: ""
		}
	}
]

export const SpringWheelOptionsArr = [
	{
		mainText: "50%",
		secondaryText: "on-two-month",
		validUntil: "2025-04-01",
		forms: {
			de: "7b6f37e4-01b3-44be-948c-7b8964bd9049",
			fr: "d692892a-a1a7-46d1-8429-3288895fa303"
		}
	},
	{
		mainText: "50%",
		secondaryText: "on-one-month",
		validUntil: "2025-04-01",
		forms: {
			de: "a8d63ec7-1159-40ce-a568-c58e9479aced",
			fr: "d7d84eff-27b7-4016-8010-9ca184a1167e"
		}
	},
	{
		mainText: "no-win-title",
		secondaryText: "",
		validUntil: "",
		forms: {
			de: "",
			fr: ""
		}
	}
]

import React, {useEffect, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

interface TimeLeft {
	days: number
	hours: number
	minutes: number
	seconds: number
}

const Countdown = (): React.ReactElement => {
	const today = new Date()
	const SpringWheelStartDate = new Date("2025-03-03T00:00:00") // Starts on March 3
	const isSpringWheel = today >= SpringWheelStartDate
	const targetDate = isSpringWheel ? "2025-04-01T00:00:00" : "2025-02-01T00:00:00"

	const {t} = useTranslation()
	const calculateTimeLeft = () => {
		const difference = Number(new Date(targetDate)) - Number(new Date())
		let timeLeft:TimeLeft = null

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			}
		} else {
			timeLeft = {days: 0, hours: 0, minutes: 0, seconds: 0}
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)

		return () => clearInterval(timer) // Cleanup interval on component unmount
	}, [targetDate])

	return (
		<div className={"row"}>
			<div className={"col-3"}>
				<Typography variant="heading1Black"
					className="text-white text-center">
					{timeLeft.days}
				</Typography>
				<Typography variant="bodyLg"
					className="text-white text-center">
					{t("countdown-days")}
				</Typography>
			</div>
			<div className={"col-3"}>
				<Typography variant="heading1Black"
					className="text-white text-center">
					{timeLeft.hours}
				</Typography>
				<Typography variant="bodyLg"
					className="text-white text-center">
					{t("countdown-hours")}
				</Typography>
			</div>
			<div className={"col-3"}>
				<Typography variant="heading1Black"
					className="text-white text-center">
					{timeLeft.minutes}
				</Typography>
				<Typography variant="bodyLg"
					className="text-white text-center">
					{t("countdown-minutes")}
				</Typography>
			</div>
			<div className={"col-3"}>
				<Typography variant="heading1Black"
					className="text-white text-center">
					{timeLeft.seconds}
				</Typography>
				<Typography variant="bodyLg"
					className="text-white text-center">
					{t("countdown-seconds")}
				</Typography>
			</div>
		</div>
	)
}

export default Countdown
